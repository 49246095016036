.alert-badge
    padding: 10px
    &.error
        background: $red
        color: $white
    &.warning
        background: $orange
        color: $white
    &.information
        background: $green
        color: $white