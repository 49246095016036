// ------------- paddings

.p-20-left
  padding-left: 20px

.p-20-right
  padding-right: 20px

.p-30-left
  padding-left: 30px

.p-4-right
  padding-right: 4px

.p-4-top
  padding-top: 4px

.p-4-bottom
  padding-bottom: 4px

.p-0
  padding: 0

.p-4
  padding: 4px

.p-5
  padding: 5px

.p-10
  padding: 10px

.p-12
  padding: 12px

.p-15
  padding: 15px

.p-20
  padding: 20px

.py-4
  padding-top: 4px
  padding-bottom: 4px

// -------------- margins
.m-0
  margin: 0

.m-5
  margin: 5px

.m-10
  margin: 10px

.m-15
  margin: 10px

.m-20
  margin: 20px

// -------------- gaps

.gap-5
  gap: 5px

.gap-10
  gap: 10px

.gap-15
  gap: 15px

.gap-20
  gap: 20px


.m-1
  margin: 5px

.mx-1
  margin-left: 5px
  margin-right: 5px

.my-1
  margin-top: 5px
  margin-bottom: 5px

.my-10
  margin-top: 10px
  margin-bottom: 10px

.my-3
  margin-top: 30px
  margin-bottom: 30px

.mr-1
  margin-right: 5px

.mr-2
  margin-right: 10px

.mb-5
  margin-bottom: 5px

.mb-2
  margin-bottom: 10px

.mb-15
  margin-bottom: 15px

.mt-10 
  margin-top: 10px

.mt-20
  margin-top: 20px
.mt-30
  margin-top: 30px

.mr-8
  margin-right: 8px

.mb-50
  margin-bottom: 50px

/* display
.gap-5
  gap: 5px

.gap-10
  gap: 10px

.gap-20
  gap: 20px

.w-100
  width: 100% !important

.w-50
  width: 50% !important

.w-25
  width: 25% !important

.z-index-20
  z-index: 20 
